import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, tap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../Authentication/services/auth/auth.service';
import { GenericResponse } from '../../../Shared/models/models';
import { UtilityService } from '../../../Shared/services/utility/utility.service';
import {
  AddToCartRequest,
  CartProduct,
  CartResponse,
  CartObject,
  AddToWishlistRequest,
  WishlistProduct,
} from '../../models/product.models';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  apiUrl = environment.apiUrl;

  guestCartSubject = new BehaviorSubject<CartObject>(new CartObject([]));
  userCartSubject = new BehaviorSubject<CartObject>(new CartObject([]));
  buyNowSubject = new BehaviorSubject<CartProduct | null>(null);
  wishlistSubject = new BehaviorSubject<WishlistProduct[] | null>(null);

  public checkoutMode = 'cart';

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private utility: UtilityService
  ) {}

  // Common for User and Guest Cart
  async addToCart(obj: AddToCartRequest) {
    let url = this.apiUrl;
    const isLoggedIn = await firstValueFrom(this.auth.userSubject);
    let headers = new HttpHeaders();

    if (isLoggedIn) {
      url += 'cart/add-to-cart';
      headers = headers.append('token', 'true');
    } else {
      url += 'cart/buy-now';
    }

    return this.http.post<CartResponse>(url, obj, { headers }).pipe(
      tap((res) => {
        if (!isLoggedIn) {
          this.handleGuestCart(res, obj.method);
        }
      })
    );
  }

  async removeFromCart(obj: { cart_id: string }, guestIndex: number) {
    const isLoggedIn = await firstValueFrom(this.auth.userSubject);

    if (isLoggedIn) {
      let url = this.apiUrl + 'cart/delete-cart';
      this.http
        .post<GenericResponse>(url, obj, { headers: { token: 'true' } })
        .subscribe({
          next: (res) => {
            if (res.status === 'success') {
              this.utility.showSuccessMessage('Product removed from cart');
              this.getUserCart();
            } else {
              this.utility.showSuccessMessage(res.msg);
            }
          },
        });
    } else {
      const currentGuestCart = await firstValueFrom(this.guestCartSubject);
      let cartProducts = [...currentGuestCart.cartContents];

      cartProducts.splice(guestIndex, 1);
      const updatedGuestCart = new CartObject(cartProducts);
      this.guestCartSubject.next(updatedGuestCart);
      localStorage.setItem('guestCart', JSON.stringify(updatedGuestCart));
    }
  }
  // Common for User and Guest Cart Ends

  // User Cart
  getUserCart() {
    let url = this.apiUrl + 'cart/cart-list';

    this.http.get<CartResponse>(url, { headers: { token: 'true' } }).subscribe({
      next: (res) => {
        if (res.status === 'success') {
          const userData = new CartObject(res.data);
          this.userCartSubject.next(userData);
        } else {
          const userData = new CartObject([]);
          this.userCartSubject.next(userData);
        }
      },
      error: (err) => {},
    });
  }
  // User Cart Ends

  // Guest Cart
  async handleGuestCart(res: CartResponse, method: string | undefined) {
    if (res.status !== 'success') return;

    const currentGuestCart = await firstValueFrom(this.guestCartSubject);
    let cartProducts = [...currentGuestCart.cartContents];

    let matchFound = false;
    cartProducts.forEach((product) => {
      if (
        product.product_Id === res.data[0].product_Id &&
        product.color_Id === res.data[0].color_Id &&
        product.size_Id === res.data[0].size_Id
      ) {
        if (method === 'sub') {
          product.quantity = (+product.quantity - 1).toString();
        } else {
          product.quantity = (
            +product.quantity + +res.data[0].quantity
          ).toString();
        }
        product.total_price = +product.quantity * product.selling_price;
        matchFound = true;
      }
    });

    let updatedGuestCart: CartObject;
    if (matchFound) {
      updatedGuestCart = new CartObject(cartProducts);
    } else {
      updatedGuestCart = new CartObject([
        ...currentGuestCart.cartContents,
        ...res.data,
      ]);
    }
    this.guestCartSubject.next(updatedGuestCart);
    localStorage.setItem('guestCart', JSON.stringify(updatedGuestCart));
  }

  setGuestCartOnStartup() {
    const data = localStorage.getItem('guestCart');

    if (data) {
      const loadedGuestCart: { _cartProducts: CartProduct[] } =
        JSON.parse(data);
      this.guestCartSubject.next(new CartObject(loadedGuestCart._cartProducts));
    }
  }

  clearGuestCart() {
    this.guestCartSubject.next(new CartObject([]));
    localStorage.removeItem('guestCart');
  }
  // Guest Cart Ends

  // Buy Now
  buyNow(obj: AddToCartRequest) {
    let url = this.apiUrl + 'cart/buy-now';

    return this.http.post<CartResponse>(url, obj).pipe(
      tap((res) => {
        this.handleBuyNow(res);
      })
    );
  }

  handleBuyNow(res: CartResponse) {
    if (res.status !== 'success') return;

    this.buyNowSubject.next(res.data[0]);
    localStorage.setItem('buyNowProduct', JSON.stringify(res.data[0]));
  }

  setBuyNowOnStartup() {
    const data = localStorage.getItem('buyNowProduct');

    if (data) {
      const buyNowProduct: CartProduct = JSON.parse(data);
      this.buyNowSubject.next(buyNowProduct);
    }
  }

  clearBuyNow() {
    localStorage.removeItem('buyNowProduct');
  }
  // Buy Now Ends

  // Wishlist
  async addToWishlist(obj: AddToWishlistRequest) {
    const user = await firstValueFrom(this.auth.userSubject);
    if (!user) {
      this.utility.showErrorMessage('Please login first');
      return;
    }

    let url = this.apiUrl + 'cart/add-to-wishlist';

    this.http
      .post<GenericResponse>(url, obj, { headers: { token: 'true' } })
      .subscribe({
        next: (res) => {
          if (res.status === 'success') {
            this.getUserWishlist();
            this.utility.showSuccessMessage("Added to Wishlist");
          }
        },
        error: (err) => {},
      });
  }

  getUserWishlist() {
    let url = this.apiUrl + 'cart/customer-wishlist';
    this.http
      .get<GenericResponse>(url, { headers: { token: 'true' } })
      .subscribe({
        next: (res) => {
          if (res.status === 'success') {
            this.wishlistSubject.next(<WishlistProduct[]>res.data);
          } else {
            this.wishlistSubject.next([]);
          }
        },
        error: (err) => {},
      });
  }

  removeFromWishlist(obj: { product_Id: string }) {
    let url = this.apiUrl + 'cart/remove-wishlist';
    this.http
      .post<GenericResponse>(url, obj, { headers: { token: 'true' } })
      .subscribe({
        next: (res) => {
          if (res.status === 'success') {
            this.getUserWishlist();
            this.utility.showSuccessMessage(res.msg);
          } else {
            this.utility.showErrorMessage(res.msg);
          }
        },
        error: (err) => {},
      });
  }
  // Wishlist Ends

  setCheckoutCart(cartProducts: CartProduct[], cartTotal: number) {
    const obj = {
      cartProducts: cartProducts,
      cartTotal: cartTotal,
    };
    localStorage.setItem('checkoutCart', JSON.stringify(obj));
  }

  getCheckoutCart() {
    const data = localStorage.getItem('checkoutCart');
    if (data) {
      return JSON.parse(data);
    } else {
      return '';
    }
  }

  clearCheckoutCart() {
    localStorage.removeItem('checkoutCart');
  }
}

export interface AddToWishlistRequest {
  product_Id: string;
  source: string;
}
export interface WishlistProduct {
  id: number;
  createdAt: string;
  updatedAt: string | null;
  createdBy: string | null;
  updatedBy: string | null;
  isActive: boolean;
  isDeleted: boolean;
  customer: string;
  product_Id: number;
  source: string;
  product_name: string;
  product_slug: string;
  main_cat_slug: string;
  sub_cat_slug: string;
  thumbnail_image: string;
  thumbnail_image_alt_text: string;
  price_range: string;
}
export interface AddToCartRequest {
  product_Id: string;
  color_Id: string;
  size_Id: string;
  source: string;
  quantity: string;
  method?: string;
}
export interface CartProduct {
  size_Id: string;
  size_name: string;
  color_Id: string;
  color_name: string;
  color_code: string | null;
  product_name: string;
  selling_price: number;
  thumbnail_image: string;
  thumbnail_image_alt_text: string;
  total_price: number;
  quantity: string;
  product_Id: string;
  product_slug: string;
  main_cat_slug: string;
  sub_cat_slug: string;
  id?: number;
}
export interface CartResponse {
  status: string;
  msg: string;
  data: CartProduct[];
  total_cart_price: number;
}

export class CartObject {
  constructor(private _cartProducts: CartProduct[]) {}

  get cartContents() {
    return this._cartProducts;
  }

  get cartItemsCount() {
    return this._cartProducts.length;
  }

  get cartTotalProductsCount() {
    return this._cartProducts.reduce<number>((tot, p) => tot + +p.quantity, 0);
  }

  get cartTotal() {
    return this._cartProducts.reduce<number>(
      (tot, p) => tot + p.total_price,
      0
    );
  }
}
